<template>
    <div>
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CCol sm="12" class="d-flex justify-content-end px-0 mb-2 mt-2">
            <CButton 
                color="danger"
                class="mr-1"
                size="sm" 
                @click="onBtnPdf()"
            >
                <CIcon name="cil-file-pdf"/>&nbsp; PDF 
            </CButton>
        </CCol>
        <CRow>
            <CCol sm="12" lg="7">
                <SimplePie
                    :Series="SummaryArray"
                    :Colors="ColorsArray"
                    :Categories="ErrorType"
                    :Tooltip="Tooltip"
                />
            </CCol>
            <CCol sm="12" lg="5">
                <CCard style="border-color: #88a6d1 !important;">
                    <CCardHeader style="background: #1e3a61 !important;" class="text-center text-white">
                        <CIcon name="table"/><b>{{$t('label.TabletUsageSummary')}}</b>
                    </CCardHeader>
                    <CCardBody style="background: #e6e6e6;" class="p-2">
                        <CCol sm="12">
                            <b style="cursor: pointer" @click="ActiveSummaryModal(ErrorLogDeviceSummaryJson[0]?.UsersWithPermissionJson,0)">
                               {{this.$t('label.UsersWithPermission')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantUsersWithPermission)}}
                            </b>
                        </CCol>
                        <CCol sm="12">
                            <b style="cursor: pointer" @click="ActiveSummaryModal(ErrorLogDeviceSummaryJson[0]?.UsersTransactionsJson,1)">
                                {{this.$t('label.UsersTransactions')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantUsersTransactions)}}
                            </b>
                        </CCol>
                        <CCol sm="12">
                            <b style="cursor: pointer" @click="ActiveSummaryModal(ErrorLogDeviceSummaryJson[0]?.DevicesEnabledJson,2)">
                                {{this.$t('label.TabletEnabled')}}:{{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantDevicesEnabled)}}
                            </b>
                        </CCol>
                        <CCol sm="12">
                            <b style="cursor: pointer" @click="ActiveSummaryModal(ErrorLogDeviceSummaryJson[0]?.DevicesTransactionsJson,3)">
                                {{this.$t('label.TableTransactions')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantDevicesTransactions)}}
                            </b>
                        </CCol>
                        <CCol sm="12">
                            <b style="cursor: pointer" @click="ActiveSummaryModal(ErrorLogDeviceSummaryJson[0]?.ErrorsJson,4)">
                                {{this.$t('label.NumberOfErrors')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantErrors)}}
                            </b>
                        </CCol>
                        <CCol sm="12">
                            <b style="cursor: pointer" @click="ActiveSummaryModal(ErrorLogDeviceSummaryJson[0]?.TransactionsJson,5)">
                                {{this.$t('label.NumberOfTransactions')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantTransactions)}}
                            </b>
                        </CCol>
                        <CCol sm="12">
                            <b style="cursor: pointer" @click="ActiveSummaryModal(ErrorLogDeviceSummaryJson[0]?.WarningMessagesJson,6)">
                                {{this.$t('label.NumberOfWarningMessages')}}: {{ValidateValue(ErrorLogDeviceSummaryJson[0]?.CantWarningMessages)}}
                            </b>
                        </CCol>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <SummaryModal
            :modal="ModalActive"
            :itemSummary="itemSummary"
            :Summary="Summary"
            @CloseModal="(ModalActive=false, itemSummary=[])"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SimplePie from "./simplePie";
import SummaryModal from "./summaryModal";

//Data
function data() {
    return {
        loadingOverlay: false,
        ModalActive: false,
        Summary: 0,
        itemSummary: [],
    };
}
//methods
function onBtnPdf() {
    if (this.ErrorLogDeviceSummaryJson.length!=0||ErrorLogDistributionJson.length!=0) {
        let pageInfo = {
            ErrorLogDeviceSummaryJson: this.ErrorLogDeviceSummaryJson,
            ErrorLogDistributionJson: this.ErrorLogDistributionJson,
            itinerarySelected: this.itinerarySelected,
        };

        localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

        let routeData = this.$router.resolve({name: 'PdfErrorSummary'});
        window.open(routeData.href, '_blank');
    }else {
        this.$notify({  
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.noRecordsAvailbleReport'),
            type: "error" 
        });
    }
}

function ActiveSummaryModal(item, Summary) {
    if (item&&item.length!=0) {
        this.itemSummary = item;
        this.Summary = Summary;
        this.ModalActive=true;
    }
}

//computed
function SummaryArray(){
    let data = [];
    this.ErrorLogDistributionJson.map(item =>{
        data.push(item.CantErrors);
    });
    return data;
}

function ColorsArray(){
    let data = [];
    this.ErrorLogDistributionJson.map(item =>{
        data.push(item.ErrorTpColor);
    });
    return data;
}

function ErrorType(){
    let _lang = this.$i18n.locale;
    let data = [];
    this.ErrorLogDistributionJson.map(item =>{
        data.push(_lang=='en'?item.ErrorTpNameEn:item.ErrorTpNameEs);
    });
    return data;
}

function Tooltip() {
    return{
        y: {
            formatter: function (val) {
                return val;
            }
        }
    }
}

function ValidateValue(Val) {
    if (Val) {
        return Val;
    } else {
        return 0;
    }
}


export default {
    name: "summary-index",
    data,
    props: {
        ErrorLogDeviceSummaryJson: {
            type: Array,
            default: [],
        },
        ErrorLogDistributionJson: {
            type: Array,
            default: [],
        },
    },
    mixins: [],
    components:{
        SimplePie,
        SummaryModal,
    },
    methods:{
        onBtnPdf,
        ActiveSummaryModal,
        ValidateValue,
    },
    computed:{
        Tooltip,
        ErrorType,
        ColorsArray,
        SummaryArray,
        ...mapState({
            user: state => state.auth.user,
            itinerarySelected: state => state.visitas.itinerarySelected,
        }),
    },
};
</script>
<style>
    
</style>