<template>
  <div class="container-fluid">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CTabs :active-tab="Tab"  @update:activeTab="handleTab">
      <CTab :title="$t('label.details')">
        <DeteilsIndex 
          :Itinerary="Itinerary"
          :Filter="Filter"
          :ErrorLogDetailJson="ErrorLogDetailJson"
        />
      </CTab>
      <CTab :title="$t('label.resume')">
        <SummaryIndex
          :ErrorLogDeviceSummaryJson="ErrorLogDeviceSummaryJson"
          :ErrorLogDistributionJson="ErrorLogDistributionJson"
        />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import DeteilsIndex from "./deteils/deteils-index";
import SummaryIndex from "./summary/summary-index";
import { DateFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
function data() {
  return {
    loadingOverlay: false,
    Tab: 0,
    ErrorLogDetailJson: [],
    ErrorLogDeviceSummaryJson: [],
    ErrorLogDistributionJson: [],
    Filter: [],
    Itinerary: [],
  };
}

function getDateRange(){
  let currentDate = new Date();
  let fromDay = '';
  let toDay = '';
  let previousDate = '';
  let laterDate = '';
  if(this.itinerarySelected.Ata!=undefined){ 
    fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
    previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
  }

  if(this.itinerarySelected.Ata==undefined){
    fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
    previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
  }

  if(this.itinerarySelected.Atd!=undefined){  
    toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
    laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
  }

  if(this.itinerarySelected.Atd==undefined){
    toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
    laterDate = currentDate;
  }

  this.Itinerary.push({
    fromDay: fromDay,
    toDay: toDay,
    previousDate: previousDate,
    laterDate: laterDate,
  })
}

function getList() {
	this.Filter = [],
	this.$http.get("SigetMobileGeneralInfoList-by-VisitId", { VisitId: this.Visit })
    .then((responses) => {
			let List =  responses.data.data;
			if (List&&List.length!=0) {
        this.Filter = List;
			}
    })
    .catch((err) => {
      this.loadingOverlay = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function handleTab(tab) {
  this.Tab = tab;
}

function getDetailsList () {
  this.ErrorLogDetailJson = [];
  this.ErrorLogDeviceSummaryJson = [];
  this.ErrorLogDistributionJson = [];
  let List = [];
  let dateStart = '';
  let dateEnd = '';

  dateStart= DateFormater.formatDateTimeWithoutSlash(this.Itinerary[0].fromDay);
  dateEnd= DateFormater.formatDateTimeWithoutSlash(this.Itinerary[0].toDay);

  let ErrorLogJson = [{
    VisitId: this.Visit,
    UserTransacId : '',
    DeviceId: '',
    Version: '',
    DateStart: dateStart,
    DateEnd: dateEnd,
  }];

  this.$http.post("VisitErrorLogSigetMobile-Report", ErrorLogJson, { root: 'ErrorLogJson' })
    .then(response => {
      List = response.data.data;
      if (Object.entries(List).length != 0) {
        this.ErrorLogDetailJson = List.ErrorLogDetailJson ? List.ErrorLogDetailJson : [];
        this.ErrorLogDeviceSummaryJson = List.ErrorLogDeviceSummaryJson ? List.ErrorLogDeviceSummaryJson : [];
        this.ErrorLogDistributionJson = List.ErrorLogDistributionJson ? List.ErrorLogDistributionJson : [];
      }
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });
}

export default {
  name: 'error-log-index',
  components: { 
    DeteilsIndex,
    SummaryIndex,
  },
  data,
  props: { currentModuleId: Boolean },
  methods: {
    getDateRange,
    getDetailsList,
    getList,
    handleTab,
  },
  computed: {
    ...mapState({
      Visit: state => state.visitas.VisitId,
      itinerarySelected: state => state.visitas.itinerarySelected,
    })
  },
  watch:{
    currentModuleId: async function (NewVal) {
      if (NewVal && this.Visit!='') {
        this.loadingOverlay = true;
        this.Tab = 0;
        await this.getDateRange();
        await this.getList();
        await this.getDetailsList();
      }
    }
  }
};
</script>