<template>
    <div>
      <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
      <CModalExtended
        :title="ModalTitle"
        color="dark"
        size="xl"
        class="modal-extended"
        @update:show="closeModal()"
        :show.sync="modalActive"
        :closeOnBackdrop="false"
      >
        <CRow>
            <CCol sm="12" class="d-flex align-items-center justify-content-end ">
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
            </CCol>
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    size="lg"
                    :fields="fields"
                    :items="computedList"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                    <template #OrderStatusDs="{item}">
                        <td class="text-center">
                            <CBadge :style="'color:'+item.ColorStatus">
                            {{ item.OrderStatusDs }}
                            </CBadge>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
        <template #footer>
          <CButton color="wipe" @click="closeModal()">
            <CIcon name="x" />&nbsp; {{$t('button.exit')}}
          </CButton>
        </template>
      </CModalExtended>
    </div>
</template>
<script>
    import GeneralMixin from '@/_mixins/general';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import { mapState } from 'vuex';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';
  
    function data() {
        return {
            modalActive: false,
            loadingOverlay: false,
        };
    }

    function closeModal() {
        this.$emit('CloseModal');
    }


  async function onBtnExport(valor) {
		this.loadingOverlay = true; 
    let currentDate = new Date();
    let Filter = [];
    let ReporExcel = [];
    let tableHeader = {label: [], key: []};
    if(this.itemSummary.length !== 0) {
      Filter[0] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
      Filter[1] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
			this.fields.map(item=>{
				tableHeader.label.push(item.label);
				tableHeader.key.push(item.key);
			})
			ReporExcel = this.computedList;
      await this.getOrdersExcel(ReporExcel, this.ModalTitle, valor, Filter, false, tableHeader);
    }else{
      this.$notify({  
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.noRecordsAvailbleReport'),
        type: "error" 
      });
    }
    this.loadingOverlay = false;   
  }
  
  
  function computedList() {
    let _lang = this.$i18n.locale;
    return this.itemSummary.map((item) => {
      return {
        ...item,
        TransactionDate: item.TransactionDate ? DateFormater.formatOnlyDateWithSlash(item.TransactionDate):'N/A',
				TransaRegDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate):'N/A',
				ErrorTpName: _lang=='en'?item.ErrorTpNameEn:item.ErrorTpNameEs,
        TpTransactionName: _lang=='en'?item.TpTransactionNameEn:item.TpTransactionNameEs,
      };
  	});
  }

  
  function fields(){
    switch (this.Summary) {
        case 0:{
            return [
                { label: '#', key: 'IdX', _classes: 'text-center', _style: 'width:3%;', filter: false},
                { label: 'LOGIN', key: 'Login', _classes: 'text-center'},
                { label: this.$t('label.user'), key: 'UserName', _classes: 'text-center'},
                { label: this.$t('label.role'), key: 'StowageRoleName', _classes: 'text-center'},
            ];
        }
        case 1:{
            return [
                { label: '#', key: 'IdX', _classes: 'text-center', _style: 'width:3%;', filter: false},
                { label: 'LOGIN', key: 'Login', _classes: 'text-center'},
                { label: this.$t('label.user'), key: 'UserName', _classes: 'text-center'},
                { label: this.$t('label.role'), key: 'StowageRoleName', _classes: 'text-center'},
                { label: this.$t('label.transactionDate'), key: 'TransaRegDate', _classes: 'text-center'},
            ];
        }
        case 2:{
            return [
                { label: '#', key: 'Idx', _classes: 'text-center', _style: 'width:3%;', filter: false},
                { label: this.$t('label.device'), key: 'DeviceName', _classes: 'text-center'},
                { label: 'LOGIN', key: 'UserDeviceLogin', _classes: 'text-center'},
                { label: this.$t('label.user'), key: 'UserDeviceName', _classes: 'text-center'},
                { label: this.$t('label.role'), key: 'StowageRoleName', _classes: 'text-center'},
            ];
        }
        case 3:{
            return [
                { label: '#', key: 'IdX', _classes: 'text-center', _style: 'width:3%;', filter: false},
                { label: this.$t('label.device'), key: 'UserDeviceName', _classes: 'text-center' },
                { label: 'LOGIN', key: 'UserDeviceLogin', _classes: 'text-center' },
                { label: this.$t('label.type'), key: 'TpTransactionName', _classes: 'text-center' },
                { label: this.$t('label.role'), key: 'StowageRoleName', _classes: 'text-center' },
            ];
        }
        case 4:{
            return [
                { label: '#', key: 'IdX', _classes: 'text-center', _style: 'width:3%;', filter: false},
                { label: this.$t('label.ErrorType'), key: 'ErrorTpName', _classes: 'text-center' },
                { label: 'LOGIN', key: 'TransaLoginErrorDetail', _classes: 'text-center' },
                { label: this.$t('label.user'), key: 'UserNameErrorDetail', _classes: 'text-center' },
                { label: this.$t('label.transactionDate'), key: 'TransactionDate', _classes: 'text-center' },
            ];
        }
        case 5:{
            return [
                { label: '#', key: 'IdX', _classes: 'text-center', _style: 'width:3%;', filter: false},
                { label: this.$t('label.type'), key: 'TpTransactionName', _classes: 'text-center' },
                { label: this.$t('label.device'), key: 'UserDeviceName', _classes: 'text-center' },
                { label: 'LOGIN', key: 'UserDeviceLogin', _classes: 'text-center' },
                { label: this.$t('label.role'), key: 'StowageRoleName', _classes: 'text-center' },
                { label: this.$t('label.transactionDate'), key: 'TransaRegDate', _classes: 'text-center' },
            ];
        }
        case 6:{
            return [
                { label: '#', key: 'IdX', _classes: 'text-center', _style: 'width:2%;', filter: false},
                { label: this.$t('label.type'), key: 'ErrorTpName', _classes: 'text-center', _style: 'width:19%;',},
                { label: this.$t('label.ErrorMessage'), key: 'Error', _classes: 'text-center', _style: 'width:19%;' },
                { label: 'LOGIN', key: 'TransaLoginErrorDetail', _classes: 'text-center', _style: 'width:19%;' },
                { label: this.$t('label.user'), key: 'UserNameErrorDetail', _classes: 'text-center', _style: 'width:19%;' },
                { label: this.$t('label.FECHA'), key: 'TransactionDate', _classes: 'text-center',_style: 'width:19%;' },
                { label: '', key: 'ShowDetail', _classes: 'text-center', _style: 'min-width:45px;max-width:45px' },
            ];
        }
        default:
            break;
    }
  }

  function ModalTitle() {
		switch (this.Summary){
			case 0:{
				return this.$t('label.UsersWithPermission');
			}
			case 1:{
				return this.$t('label.UsersTransactions');
			}
			case 2:{
				return this.$t('label.TabletEnabled');
			}
			case 3:{
				return this.$t('label.TableTransactions');
			}
			case 4:{
				return this.$t('label.NumberOfErrors');
			}
			case 5:{
				return this.$t('label.NumberOfTransactions');
			}
			case 6:{
				return this.$t('label.NumberOfWarningMessages');
			}
			default:
      	break;
		}
  }
  
  export default {
    name: 'modal-hold-information',
    props: { 
      modal: Boolean, 
      itemSummary: {
        Type: Array,
        default: [],
      },
      Summary: {
        type: Number,
        default: 0,
      }
     },
    data,
    mixins: [
      GeneralMixin,
      ReportesVisitas,
    ],
    methods: {
      onBtnExport,
      closeModal,
    },
    computed: {
      ModalTitle,
      computedList,
      fields,
      ...mapState({
				user: state => state.auth.user,
        branch: state => state.auth.branch,
      }),
    },
    watch: {
        modal: function(val) {
        	this.modalActive = val;
        },
    },
    
  };
</script>